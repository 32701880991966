// import axios from 'axios'
// import nookies from 'nookies'

// export class AxiosHttpClient {
//   #api
//   #baseUrl

//   constructor (baseUrl) {
//     this.#baseUrl = baseUrl
//     const api = axios
//     api.interceptors.response.use(this.#handleSuccess, this.#handleError)
//     this.#api = api
//   }

//   async request ({ url, method, data, headers = {}, params }) {
//     let axiosResponse

//     try {
//       const token = this.#getJwtTokenFromCookies()
//       axiosResponse = await this.#api.request({
//         url: this.#baseUrl + url,
//         method,
//         data,
//         headers: {
//           ...token,
//           ...headers
//         },
//         params
//       })
//     } catch (error) {
//       axiosResponse = error.response
//     }

//     return {
//       statusCode: axiosResponse.status,
//       body: axiosResponse.data
//     }
//   }

//   #getJwtTokenFromCookies = () => {
//     const cookies = nookies.get()
//     const jwtToken = cookies.jwtToken

//     return jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}
//   }

//   #handleSuccess = (response) => {
//     return response
//   }

//   #handleError = (error) => {
//     const { status } = error.response
//     switch (status) {
//       case 401:
//         // this.#redirectTo(document, '/sair')
//         alert('AQUI')
//         break
//       case 403:
//         this.#redirectTo(document, '/403')
//         break
//       case 404:
//         this.#redirectTo(document, '/404')
//         break
//     }
//     return Promise.reject(error)
//   }

//   #redirectTo = (document, path) => {
//     document.location = path
//   }
// }

import axios from 'axios'
import nookies from 'nookies'
import { refreshToken } from '../../auth-jwt/auth'

export class AxiosHttpClient {
  #api;
  #baseUrl;
  #isRefreshing = false;
  #failedRequestsQueue = [];

  constructor (baseUrl) {
    this.#baseUrl = baseUrl
    this.#api = axios.create()
    this.#api.interceptors.response.use(this.#handleSuccess, this.#handleError)
  }

  async request ({ url, method, data, headers = {}, params }) {
    try {
      const token = this.#getJwtTokenFromCookies()
      const response = await this.#api.request({
        url: this.#baseUrl + url,
        method,
        data,
        headers: {
          ...token,
          ...headers
        },
        params
      })
      return { statusCode: response.status, body: response.data }
    } catch (error) {
      return error.response ? { statusCode: error.response.status, body: error.response.data } : { statusCode: 500, body: {} }
    }
  }

  #getJwtTokenFromCookies = () => {
    const cookies = nookies.get()
    return cookies.jwtToken ? { Authorization: `Bearer ${cookies.jwtToken}` } : {}
  }

  #handleSuccess = (response) => response

  #handleError = async (error) => {
    if (!error.response) return Promise.reject(error)
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      if (this.#isRefreshing) {
        return new Promise((resolve, reject) => {
          this.#failedRequestsQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            return this.#api(originalRequest)
          })
          .catch(Promise.reject)
      }

      originalRequest._retry = true
      this.#isRefreshing = true

      try {
        const refreshResponse = await refreshToken()
        const newToken = refreshResponse?.data?.data?.token

        if (newToken) {
          nookies.set(undefined, 'jwtToken', newToken, { maxAge: 86400, path: '/' })
          this.#api.defaults.headers.common.Authorization = `Bearer ${newToken}`
          this.#redirectTo('/')
          this.#failedRequestsQueue.forEach(req => req.resolve(newToken))
          this.#failedRequestsQueue = []
          return this.#api(originalRequest)
        }
      } catch (refreshError) {
        this.#failedRequestsQueue.forEach(req => req.reject(refreshError))
        this.#failedRequestsQueue = []
        this.#redirectTo('/login')
        return Promise.reject(refreshError)
      } finally {
        this.#isRefreshing = false
      }
    }

    if ([403, 404].includes(error.response.status)) {
      this.#redirectTo(`/${error.response.status}`)
    }

    return Promise.reject(error)
  }

  #redirectTo = (path) => {
    // eslint-disable-next-line no-console
    console.log(`Redirecionando para: ${path}`)
    window.location.replace(path)
  }
}
