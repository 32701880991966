import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch, Route as RouteDom } from 'react-router-dom'
import { GuardProvider } from 'react-router-guards'
import { guardsRoutes } from './utils/functions'
import Loading from '../loading'
import Route from './route'

// Layouts base
import AuthLayout from '../../../main/factories/layouts/auth-factory'
import BaseLayout from '../../../main/factories/layouts/base-factory'
import { Blank } from '../layouts'
import Conquests from '../../pages/conquests/conquest-main/conquest-main'
import ConquestInterna from '../../pages/conquests/conquest-internal/conquest-internal'
import TodasJuntasNewPost from '../../pages/todasjuntas/newPost'
import NotesList from '../../pages/notas/notas'
import NotesInterna from '../../pages/notas/notas-interna'
import ContentsWatched from '../../pages/contents/contents-watched/contents-watched'
import Faq from '../../pages/faq/faq'
import SeasonPage from '../../pages/contents/season-page/season-page'

// Mentor
import Mentor from '../../pages/mentor/mentor-page'
import Zoom from '../../pages/zoom-live/zoom-live-page'

// Amplify
const SignIn = lazy(() => import('../../pages/amplify/sign_in'))
const SignUp = lazy(() => import('../../pages/amplify/sign_up'))
const ConfirmSignUp = lazy(() => import('../../pages/amplify/confirm_sign_up'))
const CreateNewPassword = lazy(() => import('../../pages/amplify/create_new_password'))
const ForgotPassword = lazy(() => import('../../pages/amplify/forgot_password'))
const PasswordRecovery = lazy(() => import('../../pages/amplify/password_recovery'))
const Logout = lazy(() => import('../../pages/amplify/logout'))

// Page Errors
const NoAuthorization = lazy(() => import('../../pages/errors/no_authorization'))
const PageNotFound = lazy(() => import('../../pages/errors/page_not_found'))
const ServerErrors = lazy(() => import('../../pages/errors/server_errors'))

// Plans & Payments
const PlansList = lazy(() => import('../../../main/factories/pages/plans/list/plans-list-factory'))
const PlanView = lazy(() => import('../../../main/factories/pages/plans/view/plan-view-factory'))
const Congratulations = lazy(() => import('../../pages/plans/congratulations/congratulations'))
const UpdateCard = lazy(() => import('../../../main/factories/pages/payments/update-card-factory'))
const MyPlan = lazy(() => import('../../../main/factories/pages/plans/my-plan/my-plan-factory'))
const MyCards = lazy(() => import('../../../main/factories/pages/plans/cards/cards-factory'))

// Profile
const ChangePassword = lazy(() => import('../../../main/factories/pages/profile/change-password'))
const MyProfile = lazy(() => import('../../../main/factories/pages/profile/my-profile'))
const MyCurriculum = lazy(() => import('../../../main/factories/pages/profile/my-curriculum'))
const Favorites = lazy(() => import('../../../main/factories/pages/profile/favorites'))
const SkillProgress = lazy(() => import('../../pages/profiles/skill-progress'))

// Academy
// const GoToApp = lazy(() => import('../../pages/go-to-app'))
const Home = lazy(() => import('../../pages/home'))
const DynamicPage = lazy(() => import('../../pages/dynamic-page/dynamic-page'))

// Contents
const TalksList = lazy(() => import('../../../main/factories/pages/content/content-list/talk-list-factory'))
const TodasProList = lazy(() => import('../../../main/factories/pages/content/content-list/todas-pro-list-factory'))
const TodasLendoList = lazy(() => import('../../../main/factories/pages/content/content-list/todas-lendo-list-factory'))
const AconteceuNaLiveList = lazy(() => import('../../../main/factories/pages/content/content-list/aconteceu-na-live-list-factory'))
const MasterClassList = lazy(() => import('../../../main/factories/pages/content/content-list/master-class-list-factory'))
const ContentPage = lazy(() => import('../../../main/factories/pages/content/content-page/page-content-factory'))
const ContentPageMobile = lazy(() => import('../../../main/factories/pages/content/content-page-mobile/content-page-mobile-factory'))

const SkillList = lazy(() => import('../../../main/factories/pages/content/content-list/skill-list-factory'))
const SkillPage = lazy(() => import('../../../main/factories/pages/content/content-page/page-skill-factory'))
const Networks = lazy(() => import('../../../main/factories/pages/network/page-network-factory'))
const TodasJuntas = lazy(() => import('../../../main/factories/pages/network/page-todasjuntas-factory'))
const TodasJuntasInterna = lazy(() => import('../../../main/factories/pages/network/page-todasjuntas-interna-factory'))

// Search
const SearchPage = lazy(() => import('../../../main/factories/pages/content/content-search/page-search-factory'))

const JobsList = lazy(() => import('../../../main/factories/pages/content/content-list/jobs-list-factory'))
const JobPage = lazy(() => import('../../../main/factories/pages/jobs/page-job-factory'))
const LivePage = lazy(() => import('../../../main/factories/pages/live/page-live-factory'))

// Assessment
// const Welcome = lazy(() => import('../../pages/assessment/welcome'))
// const ChoicePlan = lazy(() => import('../../pages/assessment/choicePlan'))
const VerifyEmail = lazy(() => import('../../pages/assessment/verify-email'))
const UpdateUser = lazy(() => import('../../pages/assessment/update-user'))
const OptIn = lazy(() => import('../../pages/assessment/optIn'))
const ImpactResearch = lazy(() => import('../../pages/assessment/impactResearch'))
const PowerResearch = lazy(() => import('../../pages/assessment/powerResearch'))
const ResultProfile = lazy(() => import('../../pages/assessment/resultProfile'))
const ProfileComplete = lazy(() => import('../../pages/assessment/profileComplete'))
const Termos = lazy(() => import('../../pages/assessment/termos'))
const UpdateProfile = lazy(() => import('../../pages/profiles/update-profile/update-profile'))

// new auth-jwt
const NewPassword = lazy(() => import('../../pages/auth/new-password'))
const SignUpB2c = lazy(() => import('../../pages/auth/sign-up-b2c'))

const Routes = () => {
  const routes = [
    {
      layout: AuthLayout,
      data: [
        { path: '/login', component: SignIn },
        { path: '/criar-nova-senha', component: CreateNewPassword },
        { path: '/cadastre-se', component: SignUp },
        { path: '/confirmar-usuario', component: ConfirmSignUp },
        { path: '/esqueci-minha-senha', component: ForgotPassword },
        { path: '/recuperar-senha', component: PasswordRecovery },
        { path: '/sair', component: Logout },

        { path: '/403', component: NoAuthorization },
        { path: '/5:number', component: ServerErrors },
        { path: '/planos', component: PlansList, meta: { auth: true, subscriptionIsNotRequired: true, verifyActiveSubscritionAndNotHasAccess: true } },
        { path: '/comprar-plano/:id', component: PlanView, meta: { auth: true, subscriptionIsNotRequired: true } },
        { path: '/parabens', component: Congratulations, meta: { auth: true } },

        { path: '/nova-senha', component: NewPassword },
        { path: '/cadastre-se-b2c', component: SignUpB2c }

      ]
    },
    {
      layout: Blank,
      data: [
        // { path: '/', component: GoToApp, meta: { auth: true } },
        // { path: '/404', component: PageNotFound }
        // { path: '/assessment', component: Welcome },
        // { path: '/ingressar', component: ChoicePlan },
        { path: '/verify-email', component: VerifyEmail },
        { path: '/update-user', component: UpdateUser },
        { path: '/opt-in', component: OptIn },
        { path: '/complete-perfil', component: ProfileComplete },
        { path: '/termos', component: Termos },
        // { path: '/pesquisa-impacto', component: ImpactResearch, meta: { auth: true } },
        { path: '/pesquisa-impacto-inicial', component: ImpactResearch, meta: { auth: true } },
        { path: '/pesquisa-impacto-final', component: ImpactResearch, meta: { auth: true } },
        // { path: '/pesquisa-potencia', component: PowerResearch, meta: { auth: true } },
        { path: '/pesquisa-potencia-inicial', component: PowerResearch, meta: { auth: true } },
        { path: '/pesquisa-potencia-final', component: PowerResearch, meta: { auth: true } },
        // { path: '/resultado-perfil', component: ResultProfile, meta: { auth: true } },
        { path: '/resultado-perfil-inicial', component: ResultProfile, meta: { auth: true } },
        { path: '/resultado-perfil-final', component: ResultProfile, meta: { auth: true } },
        { path: '/atualiza-perfil', component: UpdateProfile, meta: { auth: true } },
        { path: '/mentoria', component: Mentor, meta: { auth: true } }

      ]
    },
    {
      layout: BaseLayout,
      data: [
        { path: '/atualizar-cartao', component: UpdateCard, meta: { auth: true } },
        { path: '/trocar-senha', component: ChangePassword, meta: { auth: true } },
        { path: '/editar-perfil', component: MyProfile, meta: { auth: true } },
        { path: '/meu-curriculo', component: MyCurriculum, meta: { auth: true } },
        { path: '/favoritos', component: Favorites, meta: { auth: true } },
        { path: '/pagamento', component: MyCards, meta: { auth: true } },

        { path: '/meu-plano', component: MyPlan, meta: { auth: true } },
        { path: '/talks', component: TalksList, meta: { auth: true } },
        { path: '/todaspro', component: TodasProList, meta: { auth: true } },
        { path: '/livros', component: TodasLendoList, meta: { auth: true } },
        { path: '/aconteceu-na-live', component: AconteceuNaLiveList, meta: { auth: true } },
        { path: '/masterclass', component: MasterClassList, meta: { auth: true } },
        { path: '/jobs', component: JobsList, meta: { auth: true } },
        { path: '/habilidades', component: SkillList, meta: { auth: true } },
        { path: '/habilidades/:id', component: SkillPage, meta: { auth: true } },
        { path: '/habilidades/:id/:season', component: SeasonPage, meta: { auth: true } },
        { path: '/networks', component: Networks, meta: { auth: true } },
        { path: '/todas-juntas', component: TodasJuntas, meta: { auth: true } },
        { path: '/todas-juntas/nova-publicacao', component: TodasJuntasNewPost, meta: { auth: true } },
        { path: '/todas-juntas/:id', component: TodasJuntasInterna, meta: { auth: true } },

        { path: '/conteudos', component: ContentsWatched, meta: { auth: true } },
        { path: '/faq', component: Faq, meta: { auth: true } },

        { path: '/notas', component: NotesList, meta: { auth: true } },
        { path: '/notas/:id', component: NotesInterna, meta: { auth: true } },
        { path: '/busca', component: SearchPage, meta: { auth: true } },
        { path: '/conquistas', component: Conquests, meta: { auth: true } },
        { path: '/conquistas/:slug', component: ConquestInterna, meta: { auth: true } },

        { path: '/', component: Home, meta: { auth: true } },
        { path: '/jobs/:slug', component: JobPage, meta: { auth: true } },
        { path: '/eventos-ao-vivo/:slug', component: LivePage, meta: { auth: true } },

        { path: '/:folder/:slug', component: ContentPage, meta: { auth: true } },
        { path: '/live-video', component: Zoom, meta: { auth: true } },
        { path: '/progresso-habilidade', component: SkillProgress, meta: { auth: true } },
        { path: '/404', component: PageNotFound },
        { path: '/:slug', component: DynamicPage },

        { path: '*', component: PageNotFound }

      ]
    }
  ]

  return (
    <BrowserRouter>
      <Switch>
        <RouteDom
          path="/mobile/:reference/:content/:progress/:token"
          exact
          component={(props) => (
            <Suspense fallback={<Loading />}>
              <ContentPageMobile {...props} />
            </Suspense>
          )}
        />

        <GuardProvider guards={[guardsRoutes]} error={PageNotFound}>
          <Route data={routes} />
        </GuardProvider>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
